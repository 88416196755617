/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

import Header from "./header"
import "./layout.css"
import GitHubLogo from "../images/github_logo.png"
import LinkedInLogo from "../images/linkedin_logo.png"

// const NavLink = styled(Link)`
//   color: black;
//   margin-left: 15px;
//   text-decoration: none;
//   display: inline-block;
//   position: relative;

//   ::after {
//     content: "";
//     position: absolute;
//     width: 100%;
//     transform: scaleX(0);
//     height: 2px;
//     bottom: 0;
//     left: 0;
//     background-color: rgba(0, 0, 0, 0.8);
//     transform-origin: bottom right;
//     transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
//   }

//   :hover::after {
//     transform: scaleX(1);
//     transform-origin: bottom left;
//   }
// `

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 0 1.0875rem 1rem;
  padding-top: 0;
`

const Footer = styled.footer`
  display: flex;
  justify-content: center;
`
const SocialLogo = styled.img`
  max-width: 22px;
  margin-left: 5px;
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Content>
          <main>{children}</main>
          <Footer>
          <a href="https://github.com/veneziacarl" target="_blank" rel="noopener noreferrer"><SocialLogo src={GitHubLogo}></SocialLogo></a> 
          <a href="https://linkedin.com/in/veneziacarl" target="_blank" rel="noopener noreferrer"><SocialLogo src={LinkedInLogo}></SocialLogo></a>
          </Footer>
        </Content>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
